<template>
  <div class="page-header">
    <div class="page-header-wrapper">
      <div class="page-header-title">
        <h1>Contact</h1>
        <span>평안건설은 열린 마음으로 당신을 기다립니다.</span>
      </div>
    </div>
  </div>

  <main class="contacts">
    <div class="container">
      <div class="row">
        <div class="col-sm-4 col-md-12 col-lg-8">
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50526.84760128802!2d126.84253970901!3d37.67440015845655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c96d08adcafd5%3A0x4424ce37eaa8f0f5!2z7Y-J7JWI6rG07ISkKOyjvCk!5e0!3m2!1sko!2skr!4v1665277245917!5m2!1sko!2skr"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div class="col-sm-4 col-md-12 col-lg-4">
          <div class="contacts-table">
            <div class="contacts-title">
              <h1>평안건설(주)</h1>
              <span>PYUNG-AN E&amp;C</span>
            </div>
            <dl class="contacts-list">
              <div class="info">
                <dt>주소</dt>
                <dd>경기도 고양시 덕양구 신원로 36, 505호</dd>
              </div>
              <div class="info">
                <dt>Email</dt>
                <dd>pyungangs2020@gmail.com</dd>
              </div>
              <div class="info">
                <dt>팩스</dt>
                <dd>02-381-6828</dd>
              </div>
              <div class="info">
                <dt>대표전화</dt>
                <dd><a href="tel:023816825">02-381-6825</a></dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
