export default [
  {
    number: 105,
    title: "평안건설(주) 20주년 기념 행사",
    image: [
      require("./images/project_images/pyung_an_project_105_01.webp"),
      require("./images/project_images/pyung_an_project_105_02.webp"),
      require("./images/project_images/pyung_an_project_105_03.webp"),
    ],
    date: "2024.05.18",
    location: "-",
    category: "기타",
    client: "-",
    floors: "-",
    size: "-",
  },
  {
    number: 104,
    title: "논현동 83-15 근린생활시설 증축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2024.12(예정)",
    location: "서울시 강남구",
    category: "업무/공장",
    client: "논현동 8315",
    floors: "지하1층, 지상4층",
    size: "1,694.49",
  },
  {
    number: 103,
    title: "동북부사료기지 창고시설 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2024.12(예정)",
    location: "경기도 포천시",
    category: "업무/공장",
    client: "서울우유 협동조합",
    floors: "지상 1층",
    size: "1,499.48",
  },

  {
    number: 102,
    title: "(주)티에스씨 시화공단 R&D사업부 증축공사",
    image: [
      require("./images/project_images/pyung_an_project_102_01.webp"),
      require("./images/project_images/pyung_an_project_102_02.webp"),
    ],
    date: "2024.11(예정)",
    location: "경기도 시흥시",
    category: "업무/공장",
    client: "(주)티에스씨",
    floors: "지상 3층",
    size: "11,771.82",
  },
  {
    number: 101,
    title: "반석초 급식실 현대화 및 증축공사",
    image: [
      require("./images/project_images/pyung_an_project_101_01.webp"),
      require("./images/project_images/pyung_an_project_101_02.webp"),
      require("./images/project_images/pyung_an_project_101_03.webp"),
      require("./images/project_images/pyung_an_project_101_04.webp"),
      require("./images/project_images/pyung_an_project_101_05.webp"),
    ],
    date: "2024.06",
    location: "경기도 화성시",
    category: "교육/연구",
    client: "경기도화성오산교육지원청",
    floors: "지상 1층",
    size: "12.48",
  },
  {
    number: 100,
    title: "화정초 교실바닥 교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2024.02",
    location: "경기도 고양시",
    category: "교육/연구",
    client: "경기도고양교육지원청",
    floors: "-",
    size: "-",
  },

  {
    number: 99,
    title: "00시설 개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2024.11",
    location: "용산구 이태원로",
    category: "기타",
    client: "대통령경호처",
    floors: "-",
    size: "-",
  },
  {
    number: 98,
    title: "23-A-00부대 시설공사",
    image: [
      require("./images/project_images/pyung_an_project_98_01.jpg"),
      require("./images/project_images/pyung_an_project_98_01.webp"),
    ],
    date: "2025.07(예정)",
    location: "경기도 가평군",
    category: "기타",
    client: "국군재정관리단",
    floors: "지하 1층, 지상 4층",
    size: "3,908.70",
  },
  {
    number: 97,
    title: "서울초이스병원 대수선공사",
    image: [
      require("./images/project_images/pyung_an_project_97_01.webp"),
      require("./images/project_images/pyung_an_project_97_02.webp"),
      require("./images/project_images/pyung_an_project_97_03.webp"),
      require("./images/project_images/pyung_an_project_97_04.webp"),
      require("./images/project_images/pyung_an_project_97_05.webp"),
    ],
    date: "2024.05",
    location: "서울시 송파구 풍납동",
    category: "의료시설",
    client: "의료법인 정애의료재단",
    floors: "지하 2층, 지상 6층",
    size: "2,220.47",
  },

  {
    number: 96,
    title: "대부도 주택 리모델링 공사",
    image: [
      require("./images/project_images/pyung_an_project_96_01.jpg"),
      require("./images/project_images/pyung_an_project_96_02.jpg"),
      require("./images/project_images/pyung_an_project_96_03.jpg"),
      require("./images/project_images/pyung_an_project_96_04.jpg"),
      require("./images/project_images/pyung_an_project_96_05.jpg"),
    ],
    date: "2023.02",
    location: "안산시 단원구 대부북동",
    category: "주거시설",
    client: "-",
    floors: "지상 1층",
    size: "99",
  },
  {
    number: 95,
    title: "지구촌교회 필그림하우스 임마누엘테라스 증축공사",
    image: [
      require("./images/project_images/pyung_an_project_95_01.jpg"),
      require("./images/project_images/pyung_an_project_95_02.jpg"),
      require("./images/project_images/pyung_an_project_95_03.jpg"),
      require("./images/project_images/pyung_an_project_95_04.jpg"),
      require("./images/project_images/pyung_an_project_95_05.jpg"),
      require("./images/project_images/pyung_an_project_95_06.jpg"),
      require("./images/project_images/pyung_an_project_95_07.jpg"),
      require("./images/project_images/pyung_an_project_95_08.jpg"),
      require("./images/project_images/pyung_an_project_95_09.jpeg"),
      require("./images/project_images/pyung_an_project_95_10.jpeg"),
      require("./images/project_images/pyung_an_project_95_11.jpeg"),
    ],
    date: "2023.01",
    location: "경기도 가평군 북면",
    category: "종교시설",
    client: "기독교한국침례회 지구촌교회",
    floors: "지상 1층",
    size: "2,170.02",
  },
  {
    number: 94,
    title: "청라 베어스 베스트 골프장 고급 주택 4",
    image: [
      require("./images/project_images/pyung_an_project_94_01.jpg"),
      require("./images/project_images/pyung_an_project_94_02.jpg"),
      require("./images/project_images/pyung_an_project_94_03.jpg"),
      require("./images/project_images/pyung_an_project_94_04.jpg"),
      require("./images/project_images/pyung_an_project_94_05.jpg"),
      require("./images/project_images/pyung_an_project_94_06.jpg"),
      require("./images/project_images/pyung_an_project_94_07.jpg"),
      require("./images/project_images/pyung_an_project_94_08.jpg"),
      require("./images/project_images/pyung_an_project_94_09.jpg"),
      require("./images/project_images/pyung_an_project_94_10.jpg"),
      require("./images/project_images/pyung_an_project_94_11.jpg"),
      require("./images/project_images/pyung_an_project_94_12.jpg"),
      require("./images/project_images/pyung_an_project_94_13.jpg"),
      require("./images/project_images/pyung_an_project_94_14.jpg"),
      require("./images/project_images/pyung_an_project_94_15.jpg"),
      require("./images/project_images/pyung_an_project_94_16.jpg"),
      require("./images/project_images/pyung_an_project_94_17.jpg"),
      require("./images/project_images/pyung_an_project_94_18.jpg"),
      require("./images/project_images/pyung_an_project_94_19.jpg"),
      require("./images/project_images/pyung_an_project_94_20.jpg"),
      require("./images/project_images/pyung_an_project_94_21.jpg"),
      require("./images/project_images/pyung_an_project_94_22.jpg"),
      require("./images/project_images/pyung_an_project_94_23.jpg"),
      require("./images/project_images/pyung_an_project_94_24.jpg"),
      require("./images/project_images/pyung_an_project_94_25.jpg"),
      require("./images/project_images/pyung_an_project_94_26.jpg"),
      require("./images/project_images/pyung_an_project_94_27.jpg"),
      require("./images/project_images/pyung_an_project_94_28.jpg"),
      require("./images/project_images/pyung_an_project_94_29.jpg"),
      require("./images/project_images/pyung_an_project_94_30.jpg"),
      require("./images/project_images/pyung_an_project_94_31.jpg"),
      require("./images/project_images/pyung_an_project_94_32.jpg"),
    ],
    date: "2023.01",
    location: "인천시 서구 청라동",
    category: "주거시설",
    client: "제이에스코리아",
    floors: "지상 2층",
    size: "285.3",
  },
  {
    number: 93,
    title: "대야동 근린생활시설 B동",
    image: [require("./images/project_images/pyung_an_project_93_01.jpg")],
    date: "2022.09",
    location: "경기도 시흥시 대야동",
    category: "업무/공장",
    client: "(주)성훈에어팩",
    floors: "지상 1층",
    size: "165.62",
  },
  {
    number: 92,
    title: "대야동 근린생활시설",
    image: [
      require("./images/project_images/pyung_an_project_92_01.jpeg"),
      require("./images/project_images/pyung_an_project_92_02.png"),
    ],
    date: "2022.09",
    location: "경기도 시흥시 대야동",
    category: "업무/공장",
    client: "(주)성훈에어팩",
    floors: "지상 3층",
    size: "802.56",
  },
  {
    number: 91,
    title: "부천시흥원예농협 로컬푸드 직매장",
    image: [
      require("./images/project_images/pyung_an_project_91_01.jpg"),
      require("./images/project_images/pyung_an_project_91_02.jpg"),
      require("./images/project_images/pyung_an_project_91_03.jpg"),
      require("./images/project_images/pyung_an_project_91_04.jpg"),
      require("./images/project_images/pyung_an_project_91_05.jpg"),
      require("./images/project_images/pyung_an_project_91_06.jpg"),
      require("./images/project_images/pyung_an_project_91_07.png"),
      require("./images/project_images/pyung_an_project_91_08.jpg"),
      require("./images/project_images/pyung_an_project_91_09.jpg"),
      require("./images/project_images/pyung_an_project_91_10.jpg"),
      require("./images/project_images/pyung_an_project_91_11.jpg"),
      require("./images/project_images/pyung_an_project_91_12.jpg"),
    ],
    date: "2022.08",
    location: "경기도 시흥시 매화동",
    category: "상업시설",
    client: "부천시흥원예농협",
    floors: "지상 2층",
    size: "1,498.45",
  },
  {
    number: 90,
    title: "고양특례시 제3별관",
    image: [
      require("./images/project_images/pyung_an_project_90_01.jpg"),
      require("./images/project_images/pyung_an_project_90_02.jpg"),
    ],
    date: "2022.06",
    location: "경기도 고양시 성사동",
    category: "공공시설",
    client: "고양시",
    floors: "지상 2층",
    size: "719.62",
  },
  {
    number: 89,
    title: "동백중 교실 및 식당 증축공사",
    image: [
      require("./images/project_images/pyung_an_project_89_01.png"),
      require("./images/project_images/pyung_an_project_89_02.jpg"),
      require("./images/project_images/pyung_an_project_89_03.jpg"),
      require("./images/project_images/pyung_an_project_89_04.jpg"),
      require("./images/project_images/pyung_an_project_89_05.jpg"),
      require("./images/project_images/pyung_an_project_89_06.jpg"),
      require("./images/project_images/pyung_an_project_89_07.png"),
      require("./images/project_images/pyung_an_project_89_08.jpg"),
    ],
    date: "2022.03",
    location: "경기도 용인시 동백동",
    category: "교육/연구",
    client: "용인교육지원청",
    floors: "지상 4층",
    size: "700.82",
  },
  {
    number: 88,
    title: "홍천 목조주택 빌라 파티오",
    image: [
      require("./images/project_images/pyung_an_project_88_01.jpg"),
      require("./images/project_images/pyung_an_project_88_02.jpg"),
      require("./images/project_images/pyung_an_project_88_03.jpg"),
      require("./images/project_images/pyung_an_project_88_04.jpg"),
      require("./images/project_images/pyung_an_project_88_05.jpg"),
      require("./images/project_images/pyung_an_project_88_06.jpg"),
      require("./images/project_images/pyung_an_project_88_07.jpg"),
      require("./images/project_images/pyung_an_project_88_08.jpg"),
      require("./images/project_images/pyung_an_project_88_09.jpg"),
      require("./images/project_images/pyung_an_project_88_10.jpg"),
      require("./images/project_images/pyung_an_project_88_11.jpg"),
      require("./images/project_images/pyung_an_project_88_12.jpg"),
      require("./images/project_images/pyung_an_project_88_13.jpg"),
      require("./images/project_images/pyung_an_project_88_14.jpg"),
    ],
    date: "2022.02",
    location: "강원도 홍천시",
    category: "주거시설",
    client: "-",
    floors: "지상 2층",
    size: "181.5",
  },
  {
    number: 87,
    title: "청라 베어스 베스트 골프장 고급 주택 3",
    image: [
      require("./images/project_images/pyung_an_project_87_01.jpg"),
      require("./images/project_images/pyung_an_project_87_02.jpg"),
      require("./images/project_images/pyung_an_project_87_03.jpg"),
      require("./images/project_images/pyung_an_project_87_04.jpg"),
      require("./images/project_images/pyung_an_project_87_05.jpg"),
      require("./images/project_images/pyung_an_project_87_06.jpg"),
      require("./images/project_images/pyung_an_project_87_07.jpg"),
      require("./images/project_images/pyung_an_project_87_08.jpg"),
      require("./images/project_images/pyung_an_project_87_09.jpg"),
      require("./images/project_images/pyung_an_project_87_10.jpg"),
      require("./images/project_images/pyung_an_project_87_11.jpg"),
      require("./images/project_images/pyung_an_project_87_12.jpg"),
      require("./images/project_images/pyung_an_project_87_13.jpg"),
      require("./images/project_images/pyung_an_project_87_14.jpg"),
      require("./images/project_images/pyung_an_project_87_15.jpg"),
      require("./images/project_images/pyung_an_project_87_16.jpg"),
      require("./images/project_images/pyung_an_project_87_17.jpg"),
      require("./images/project_images/pyung_an_project_87_18.jpg"),
      require("./images/project_images/pyung_an_project_87_19.jpg"),
    ],
    date: "2022.02",
    location: "인천시 서구 청라동",
    category: "주거시설",
    client: "샤인코리아",
    floors: "지상 2층",
    size: "298.63",
  },
  {
    number: 86,
    title: "용인 용덕동 단독주택",
    image: [
      require("./images/project_images/pyung_an_project_86_01.jpg"),
      require("./images/project_images/pyung_an_project_86_02.jpg"),
      require("./images/project_images/pyung_an_project_86_03.jpg"),
      require("./images/project_images/pyung_an_project_86_04.jpg"),
      require("./images/project_images/pyung_an_project_86_05.jpg"),
      require("./images/project_images/pyung_an_project_86_06.jpg"),
      require("./images/project_images/pyung_an_project_86_07.jpg"),
    ],
    date: "2021.09",
    location: "용인 기흥구 용덕동",
    category: "주거시설",
    client: "-",
    floors: "지상 2층",
    size: "194.7",
  },
  {
    number: 85,
    title: "사옥 시설공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2021.06",
    location: "서울시 강서구 화곡동",
    category: "업무/공장",
    client: "(주)알디스금융시스템",
    floors: "-",
    size: "-",
  },
  {
    number: 84,
    title: "00주차장 보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2021.06",
    location: "서울시 종로구",
    category: "리모델링",
    client: "대통령 경호처",
    floors: "-",
    size: "-",
  },
  {
    number: 83,
    title: "청라 베어스 베스트 골프장 고급 주택 2",
    image: [
      require("./images/project_images/pyung_an_project_83_01.jpg"),
      require("./images/project_images/pyung_an_project_83_02.jpg"),
      require("./images/project_images/pyung_an_project_83_03.jpg"),
      require("./images/project_images/pyung_an_project_83_04.jpg"),
      require("./images/project_images/pyung_an_project_83_05.jpg"),
      require("./images/project_images/pyung_an_project_83_06.jpg"),
      require("./images/project_images/pyung_an_project_83_07.jpg"),
      require("./images/project_images/pyung_an_project_83_08.jpg"),
      require("./images/project_images/pyung_an_project_83_09.jpg"),
      require("./images/project_images/pyung_an_project_83_10.jpg"),
      require("./images/project_images/pyung_an_project_83_11.jpg"),
      require("./images/project_images/pyung_an_project_83_12.jpg"),
      require("./images/project_images/pyung_an_project_83_13.jpg"),
      require("./images/project_images/pyung_an_project_83_14.jpg"),
      require("./images/project_images/pyung_an_project_83_15.jpg"),
      require("./images/project_images/pyung_an_project_83_16.jpg"),
      require("./images/project_images/pyung_an_project_83_17.jpg"),
    ],
    date: "2021.03",
    location: "인천시 서구 청라동",
    category: "주거시설",
    client: "곽선영",
    floors: "지상 3층",
    size: "293.91",
  },
  {
    number: 82,
    title: "안산농협 공동구판장 및 창고 신축공사",
    image: [
      require("./images/project_images/pyung_an_project_82_01.jpg"),
      require("./images/project_images/pyung_an_project_82_02.jpg"),
      require("./images/project_images/pyung_an_project_82_03.jpg"),
      require("./images/project_images/pyung_an_project_82_04.jpg"),
      require("./images/project_images/pyung_an_project_82_05.jpg"),
      require("./images/project_images/pyung_an_project_82_06.jpg"),
      require("./images/project_images/pyung_an_project_82_07.jpg"),
      require("./images/project_images/pyung_an_project_82_08.jpg"),
      require("./images/project_images/pyung_an_project_82_09.jpg"),
      require("./images/project_images/pyung_an_project_82_10.jpg"),
      require("./images/project_images/pyung_an_project_82_11.jpg"),
      require("./images/project_images/pyung_an_project_82_12.jpg"),
      require("./images/project_images/pyung_an_project_82_13.jpg"),
      require("./images/project_images/pyung_an_project_82_14.jpg"),
      require("./images/project_images/pyung_an_project_82_15.jpg"),
    ],
    date: "2020.09",
    location: "경기도 시흥시 물왕동",
    category: "상업시설",
    client: "경기도안산농업협동조합",
    floors: "지상 2층",
    size: "1,646.38",
  },

  {
    number: 81,
    title: "고양 덕양구 전원주택단지",
    image: [
      require("./images/project_images/pyung_an_project_81_01.png"),
      require("./images/project_images/pyung_an_project_81_02.png"),
      require("./images/project_images/pyung_an_project_81_03.png"),
      require("./images/project_images/pyung_an_project_81_04.png"),
      require("./images/project_images/pyung_an_project_81_05.png"),
      require("./images/project_images/pyung_an_project_81_06.png"),
      require("./images/project_images/pyung_an_project_81_07.png"),
      require("./images/project_images/pyung_an_project_81_08.png"),
      require("./images/project_images/pyung_an_project_81_09.png"),
      require("./images/project_images/pyung_an_project_81_10.png"),
      require("./images/project_images/pyung_an_project_81_11.png"),
      require("./images/project_images/pyung_an_project_81_12.png"),
      require("./images/project_images/pyung_an_project_81_13.png"),
      require("./images/project_images/pyung_an_project_81_14.png"),
      require("./images/project_images/pyung_an_project_81_15.png"),
      require("./images/project_images/pyung_an_project_81_16.png"),
      require("./images/project_images/pyung_an_project_81_17.png"),
      require("./images/project_images/pyung_an_project_81_18.png"),
      require("./images/project_images/pyung_an_project_81_19.png"),
      require("./images/project_images/pyung_an_project_81_20.png"),
      require("./images/project_images/pyung_an_project_81_21.png"),
    ],
    date: "2020.06",
    location: "고양시 덕양구 성사동",
    category: "주거시설",
    client: "손선영외2인",
    floors: "지상 2층",
    size: "584.1",
  },

  {
    number: 80,
    title: "케피온 예산공장",
    image: [
      require("./images/project_images/pyung_an_project_80_01.jpg"),
      require("./images/project_images/pyung_an_project_80_02.jpg"),
      require("./images/project_images/pyung_an_project_80_03.jpg"),
      require("./images/project_images/pyung_an_project_80_04.jpg"),
    ],
    date: "2019.02",
    location: "충남 예산군 예당산업단지",
    category: "업무/공장",
    client: "(주)케피온",
    floors: "지상 2층",
    size: "1,620.37",
  },
  {
    number: 79,
    title: "청라 베어스 베스트 골프장 고급 주택 1",
    image: [
      require("./images/project_images/pyung_an_project_79_01.png"),
      require("./images/project_images/pyung_an_project_79_02.png"),
      require("./images/project_images/pyung_an_project_79_03.png"),
      require("./images/project_images/pyung_an_project_79_04.png"),
      require("./images/project_images/pyung_an_project_79_05.png"),
      require("./images/project_images/pyung_an_project_79_06.png"),
      require("./images/project_images/pyung_an_project_79_07.png"),
      require("./images/project_images/pyung_an_project_79_08.png"),
      require("./images/project_images/pyung_an_project_79_09.png"),
      require("./images/project_images/pyung_an_project_79_10.png"),
      require("./images/project_images/pyung_an_project_79_11.png"),
      require("./images/project_images/pyung_an_project_79_12.png"),
      require("./images/project_images/pyung_an_project_79_13.png"),
      require("./images/project_images/pyung_an_project_79_14.png"),
      require("./images/project_images/pyung_an_project_79_15.png"),
      require("./images/project_images/pyung_an_project_79_16.png"),
    ],
    date: "2018.10",
    location: "인천시 청라동",
    category: "주거시설",
    client: "김명환",
    floors: "지하 1층, 지상 2층",
    size: "660",
  },
  {
    number: 78,
    title: "생극산업단지 (주)디씨에스 공장 신축공사",
    image: [
      require("./images/project_images/pyung_an_project_78_01.jpg"),
      require("./images/project_images/pyung_an_project_78_02.jpg"),
      require("./images/project_images/pyung_an_project_78_03.jpg"),
    ],
    date: "2018.05",
    location: "충북 음성군 생극산업단지",
    category: "업무/공장",
    client: "(주)디씨에스",
    floors: "지상3층",
    size: "7,965.42",
  },
  {
    number: 77,
    title: "00청사 지하층 환경개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2018.05",
    location: "서울시 종로구",
    category: "리모델링",
    client: "대통령 경호실",
    floors: "-",
    size: "-",
  },
  {
    number: 76,
    title: "청라 점포주택",
    image: [
      require("./images/project_images/pyung_an_project_76_01.png"),
      require("./images/project_images/pyung_an_project_76_02.png"),
      require("./images/project_images/pyung_an_project_76_03.png"),
      require("./images/project_images/pyung_an_project_76_04.png"),
      require("./images/project_images/pyung_an_project_76_05.png"),
      require("./images/project_images/pyung_an_project_76_06.png"),
      require("./images/project_images/pyung_an_project_76_07.png"),
      require("./images/project_images/pyung_an_project_76_08.png"),
      require("./images/project_images/pyung_an_project_76_09.png"),
      require("./images/project_images/pyung_an_project_76_10.png"),
      require("./images/project_images/pyung_an_project_76_11.png"),
      require("./images/project_images/pyung_an_project_76_12.png"),
      require("./images/project_images/pyung_an_project_76_13.png"),
      require("./images/project_images/pyung_an_project_76_14.png"),
    ],
    date: "2017.09",
    location: "인천시 청라동",
    category: "주거시설",
    client: "-",
    floors: "지상 4층",
    size: "445.5",
  },

  {
    number: 75,
    title: "다마요팩 공장 신축공사",
    image: [require("./images/project_images/pyung_an_project_75_01.png")],
    date: "2017.06",
    location: "경기도 파주시 상지석동",
    category: "업무/공장",
    client: "(주)다마요팩",
    floors: "지상2층",
    size: "4,987.13",
  },
  {
    number: 74,
    title: "00아파트 주차장 개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2017.06",
    location: "서울시 종로구",
    category: "리모델링",
    client: "대통령 경호실",
    floors: "-",
    size: "-",
  },
  {
    number: 73,
    title: "청와대 접철문 철거 및 포장공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2017.05",
    location: "서울시 종로구",
    category: "리모델링",
    client: "대통령 경호실",
    floors: "-",
    size: "-",
  },

  {
    number: 72,
    title: "평택 다세대주택",
    image: [
      require("./images/project_images/pyung_an_project_72_01.png"),
      require("./images/project_images/pyung_an_project_72_02.png"),
      require("./images/project_images/pyung_an_project_72_03.png"),
      require("./images/project_images/pyung_an_project_72_04.png"),
      require("./images/project_images/pyung_an_project_72_05.png"),
      require("./images/project_images/pyung_an_project_72_06.png"),
      require("./images/project_images/pyung_an_project_72_07.png"),
      require("./images/project_images/pyung_an_project_72_08.png"),
      require("./images/project_images/pyung_an_project_72_09.png"),
      require("./images/project_images/pyung_an_project_72_10.png"),
      require("./images/project_images/pyung_an_project_72_11.png"),
      require("./images/project_images/pyung_an_project_72_12.png"),
      require("./images/project_images/pyung_an_project_72_13.png"),
    ],
    date: "2017.02",
    location: "경기도 평택시",
    category: "주거시설",
    client: "-",
    floors: "지상4층",
    size: "445.5",
  },

  {
    number: 71,
    title: "상림리공장 증축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2016.12",
    location: "경기도 광주시",
    category: "업무/공장",
    client: "(주)아방",
    floors: "지상1층",
    size: "326.7",
  },
  {
    number: 70,
    title: "00지역 차량유개호 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2016.05",
    location: "경기도 고양시 ",
    category: "기타",
    client: "제3182부대",
    floors: "지상 1층",
    size: "-",
  },
  {
    number: 69,
    title: "기지경계울타리 및 초소 신축 시설공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2015.05",
    location: "경기도 군포시",
    category: "기타",
    client: "공군방공관제사령부",
    floors: "-",
    size: "-",
  },
  {
    number: 68,
    title: "가락동 주택 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2013.05",
    location: "-",
    category: "주거시설",
    client: "남상준",
    floors: "-",
    size: "-",
  },
  {
    number: 67,
    title: "오산리 개인주택 신축공사",
    image: [require("./images/project_images/pyung_an_project_67_01.jpg")],
    date: "2013.04",
    location: "-",
    category: "주거시설",
    client: "신은주",
    floors: "-",
    size: "-",
  },
  {
    number: 66,
    title: "00부대 독신숙소 신축 시설공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2012.12",
    location: "-",
    category: "기타",
    client: "국군재정관리단",
    floors: "-",
    size: "-",
  },
  {
    number: 65,
    title: "가좌공원내 렉산 설치공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2012.02",
    location: "-",
    category: "리모델링",
    client: "고양시푸른도시사업소",
    floors: "-",
    size: "-",
  },
  {
    number: 64,
    title: "한성회관 리모델링공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2011.11",
    location: "-",
    category: "리모델링",
    client: "제3368부대",
    floors: "-",
    size: "-",
  },
  {
    number: 63,
    title: "경기경찰청 기동단 차량 차고지 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2011.10",
    location: "-",
    category: "기타",
    client: "경기경찰청",
    floors: "-",
    size: "-",
  },
  {
    number: 62,
    title: "가좌공원내 배드민턴장 증축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2011.08",
    location: "-",
    category: "리모델링",
    client: "고양시푸른도시사업소",
    floors: "-",
    size: "-",
  },
  {
    number: 61,
    title: "비상대기 숙소 보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2011.08",
    location: "-",
    category: "리모델링",
    client: "법무부 서울지방교정청",
    floors: "-",
    size: "-",
  },
  {
    number: 60,
    title: "삼천리 안산사무실 보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2011.06",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 59,
    title: "00지역 개수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2011.06",
    location: "-",
    category: "리모델링",
    client: "제1017부대",
    floors: "-",
    size: "-",
  },
  {
    number: 58,
    title: "신사동 24-22외 1필지 근생 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2010.10",
    location: "-",
    category: "상업시설",
    client: "청안레지던스",
    floors: "-",
    size: "-",
  },
  {
    number: 57,
    title: "과천중학교 교실 증축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2010.09",
    location: "-",
    category: "교육/연구",
    client: "경기도안양과천교육청",
    floors: "-",
    size: "-",
  },
  {
    number: 56,
    title: "판곡중학교 다목적강당 증축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2010.08",
    location: "-",
    category: "교육/연구",
    client: "경기도 구리남양주교육청",
    floors: "-",
    size: "-",
  },
  {
    number: 55,
    title: "산본고등학교 급식실 및 다목적교실 증축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2009.10",
    location: "-",
    category: "교육/연구",
    client: "경기도 교육청",
    floors: "-",
    size: "-",
  },
  {
    number: 54,
    title: "00지역 증축 및 개수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2009.06",
    location: "-",
    category: "리모델링",
    client: "제1017부대",
    floors: "-",
    size: "-",
  },
  {
    number: 53,
    title: "기독교 한국 침례회 새중앙교회 신축공사",
    image: [
      require("./images/project_images/pyung_an_project_53_01.jpg"),
      require("./images/project_images/pyung_an_project_53_02.png"),
      require("./images/project_images/pyung_an_project_53_03.png"),
      require("./images/project_images/pyung_an_project_53_04.png"),
    ],
    date: "2009.04",
    location: "-",
    category: "종교시설",
    client: "파주 새중앙교회",
    floors: "-",
    size: "-",
  },

  {
    number: 52,
    title: "동화중고 C동 급식소 증축 및 개보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2009.02",
    location: "-",
    category: "교육/연구",
    client: "동화고등학교",
    floors: "-",
    size: "-",
  },
  // {
  //   number: 51,
  //   title: "기독교 한국 침례회 새중앙교회 신축공사",
  //   image: [require("./images/project_images/pyung_an_project_none.jpg")],
  //   date: "2008.03",
  //   location: "-",
  //   category: "종교시설",
  //   client: "새중앙교회",
  //   floors: "-",
  //   size: "-",
  // },
  {
    number: 50,
    title: "삼천리 연수원 건물보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2007.05",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 49,
    title: "삼천리 옥상바닥 우레탄 작업 및 지하1층 방수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2007.04",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 48,
    title: "삼천리 중부지역본부 방수 및 보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2007.04",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 47,
    title: "삼천리 화장실 위생도기 교체 및 오,배수배관교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2007.03",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 46,
    title: "삼천리 정화조,터보냉동기 기기설비 동력간선라인 교체 및 부대공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2007.02",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 45,
    title: "삼천리 본사 기사대기실 개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.02",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 44,
    title: "삼천리 본사 지하층 조도개선 및 기구교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.02",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 43,
    title: "삼천리 경영정보팀 금고 철거공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.10",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 42,
    title: "성내동 단독주택 및 근린생활시설",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.08",
    location: "-",
    category: "주거시설",
    client: "리디벨롭먼트",
    floors: "-",
    size: "-",
  },
  {
    number: 41,
    title: "삼천리본사 구내식당 및 LAB실 설계변경공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.07",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 40,
    title: "장위복지관 부대공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.05",
    location: "-",
    category: "리모델링",
    client: "장위복지관",
    floors: "-",
    size: "-",
  },
  {
    number: 39,
    title: "삼천리 지하분수대 철거공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.05",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 38,
    title: "00지역취사식당 보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.05",
    location: "-",
    category: "리모델링",
    client: "국군제 8090부대",
    floors: "-",
    size: "-",
  },
  {
    number: 37,
    title: "신당동 다세대주택 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2006.04",
    location: "-",
    category: "주거시설",
    client: "우리실업",
    floors: "-",
    size: "-",
  },
  {
    number: 36,
    title: "삼천리본사 2층 천정교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.12",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 35,
    title: "삼천리사옥 전기실 앵글설치공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.12",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 34,
    title: "삼천리 기계실 에폭시공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.12",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 33,
    title: "삼천리본사 구내식당 외 이전공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.12",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 32,
    title: "삼천리 본사 10층 개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 31,
    title: "삼천리 숙직실 개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 30,
    title: "동성고등학교 시청각실개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.10",
    location: "-",
    category: "교육/연구",
    client: "동성고등학교장",
    floors: "-",
    size: "-",
  },
  {
    number: 29,
    title: "청담빌딩 주차장 개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.10",
    location: "-",
    category: "리모델링",
    client: "청담빌딩1호",
    floors: "-",
    size: "-",
  },
  {
    number: 28,
    title: "사격장 차양대 설치공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.08",
    location: "-",
    category: "기타",
    client: "제5067부대",
    floors: "-",
    size: "-",
  },
  {
    number: 27,
    title: "삼천리 본사씽크대 교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.08",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 26,
    title: "삼천리 본사 담장 보수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.07",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 25,
    title: "삼천리 연수원 시설개선공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.07",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 24,
    title: "5층 냉난방배관 및 소방설비 교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.05",
    location: "-",
    category: "리모델링",
    client: "(사)전국경제인연합회",
    floors: "-",
    size: "-",
  },
  {
    number: 23,
    title: "삼천리본사 배관교체 및 부대공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.04",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 22,
    title: "개곡리창고 신축공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.04",
    location: "-",
    category: "업무/공장",
    client: "(주)에이알엠코리아",
    floors: "-",
    size: "-",
  },
  {
    number: 21,
    title: "삼천리 평택 1대행사 사무실공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.03",
    location: "-",
    category: "업무/공장",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 20,
    title: "장위종합사회복지관 내부공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.02",
    location: "-",
    category: "리모델링",
    client: "장위종합사회복지관",
    floors: "-",
    size: "-",
  },
  {
    number: 19,
    title: "신월동 근린생활시설",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.01",
    location: "-",
    category: "상업시설",
    client: "스페이스원",
    floors: "-",
    size: "-",
  },
  {
    number: 18,
    title: "삼천리본사 배관교체공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2005.01",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 17,
    title: "삼천리 남부지역본부 내부공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 16,
    title: "삼천리 남부지역본부 사무실 앵글선반 설치공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 15,
    title: "삼천리 수원 1직영 사무실 내부공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 14,
    title: "삼천리 안산 제2안전관리대행사 사무실 내부공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 13,
    title: "삼천리 연수원 오수정화조공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.11",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 12,
    title: "장위종합사회복지관 탄성바닥재 공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.10",
    location: "-",
    category: "리모델링",
    client: "장위종합사회복지관",
    floors: "-",
    size: "-",
  },
  {
    number: 11,
    title: "삼천리 조경공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.10",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 10,
    title: "삼천리 부천1직영 사무실 내부공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.10",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 9,
    title: "종암어린이집 창호 차음시설",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.09",
    location: "-",
    category: "리모델링",
    client: "종암어린이집",
    floors: "-",
    size: "-",
  },
  {
    number: 8,
    title: "종암어린이집 탄성바닥제 시공",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.09",
    location: "-",
    category: "리모델링",
    client: "종암어린이집",
    floors: "-",
    size: "-",
  },
  {
    number: 7,
    title: "장위종합사회복지관 전기온돌 판넬공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.09",
    location: "-",
    category: "리모델링",
    client: "장위종합사회복지관",
    floors: "-",
    size: "-",
  },

  {
    number: 6,
    title: "삼천리 평택안전센터 방수공사",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.09",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 5,
    title: "삼천리 중부지사 문서고 조명 보수",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.09",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 4,
    title: "삼천리 본사 전기설비교체",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2017.02",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 3,
    title: "삼천리 본사 직원식당 보수",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.08",
    location: "-",
    category: "리모델링",
    client: "(주)삼천리",
    floors: "-",
    size: "-",
  },
  {
    number: 2,
    title: "장위종합사회복지관 놀이기구 철거",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.08",
    location: "-",
    category: "공공시설",
    client: "장석어린이집",
    floors: "-",
    size: "-",
  },
  {
    number: 1,
    title: "장위종합사회복지관 담장 설치",
    image: [require("./images/project_images/pyung_an_project_none.jpg")],
    date: "2004.08",
    location: "-",
    category: "공공시설",
    client: "장위종합사회복지관",
    floors: "-",
    size: "-",
  },
];
